.alert-enter {
    opacity: 0.1;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0.1;
    transition: opacity 500ms;
}

.fadeIn {
    display: inline-block;
    animation: fadeIn 1000ms linear forwards;
    width: 330px;
    margin: auto
}

.fadeIn1 {
    animation: fadeIn 1000ms linear forwards;
}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }